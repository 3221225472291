.pagination-div{
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap:20px;
}

.pagination-div button{
    padding: 10px 15px;
    color:#253837;
}

.pagination-div span{
    font-size: 18px;
}
