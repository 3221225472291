.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#theme-switch {
  position: relative;
  width: fit-content;
  height: fit-content;
  touch-action: pan-x;
  user-select: none;
}

#theme-switch input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

#theme-switch .switch-track {
  height: 40px;
  width: 90px;
  border-radius: 30px;
  transition: all 0.2s ease 0s;
  background: linear-gradient(180deg, #21A0DA -28.9%, #9DD382 100%), #FFFFFF;
}

#theme-switch .switch-check,
#theme-switch .switch-x {
  bottom: 0px;
  top: 41%;
  position: absolute;
  margin: 0 0px;
  transform: translate(0px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

#theme-switch .switch-icon svg {
  font-size: 20px;
  color: #FFAB08;
}

#theme-switch .switch-check {
  left: 15px;
  opacity: 0;
  transition: opacity 0.25s ease 0s;
}

#theme-switch .switch-x {
  right: 15px;
  opacity: 1;
  transition: opacity 0.25s ease 0s;
}

#theme-switch .switch-thumb {
  background-color: #fafafa;
  height: 30px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-image: initial;
  border-radius: 20px;
  transition: all 0.25s ease 0s;
}

[darkmode] #theme-switch .switch-check {
  opacity: 1;
}

[darkmode] #theme-switch .switch-x {
  opacity: 0;
}

[darkmode] #theme-switch .switch-thumb {
  left: 36px;
}

.global-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.global-loader .child-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* css to provide margin so that content is visible*/
.margin-div {
  margin-top: 10rem;
}

.child-loader p {
  margin-top: 10px;
  font-size: 24px;
  color: red;
  text-align: center;
  width: 100%;
}

.spinner-loader {
  /* position: absolute;
  margin-top:20rem;
  margin-left:10rem; */
  font-size: 1.5rem;
  color: #DE4724;
}

.spinner-loader p {
  margin-top: 2rem;
  justify-content: center;
}


.center {
  border: 5px solid;
  margin: auto;
  width: 50%;
  padding: 10px;
}

.finance-container {
  font-family: arial;
  font-size: 24px;
  margin: 25px;
  margin-left: 35%;
  margin-top: 25%px;
  width: 350px;
  height: 200px;
  /* outline: dashed 1px black; */
  position: relative;
}

.child {
  width: 80%;
  margin-left: 20%;
  position: absolute;
  top: 50%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* BELOW 2 ARE USED TO HIDE THE SNACKBAR AND TOAST USING CSS */
/* .Toastify {
  display: none !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.css-cpgvjg-MuiSnackbar-root {
  display: none;
} */


/* Loader Responsive css  */

@media (max-width: 767px) {
  .global-loader .child-loader svg {
    width: 40px;
    height: 40px;
  }

  .child-loader p {
    margin-top: 5px;
    font-size: 16px;
  }
}